<template>
  <div class="h-screen flex w-full">
    <div class='self-center m-auto'>
      <span v-if="!isAuthenticated" class='text-black'>Please sign-in to continue.</span>
      <button v-else type="primary" v-on:click="goToHome" class='bg-black text-white border border-transparent px-2 py-2 hover:bg-white hover:text-black hover:border-black'>continue your journey</button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useMsalAuthentication } from "../composition-api/useMsalAuthentication";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";

const store = useStore()
const router = useRouter();

function goToHome() {
  router.push("/");
}

const state = reactive({
	resolved: false,
	data: {}
});

const { result, acquireToken } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
const isAuthenticated = useIsAuthenticated()

async function setAccessToken() {
  if (result.value) {
    console.log(result.value)
    store.commit('setAccessToken', result.value.accessToken)
    store.commit('setUser', result.value)
    state.data = {};
		state.resolved = true;
  } else {
    acquireToken()
  }
}

setAccessToken();

watch(result, () => {
	setAccessToken();
});
</script>
